export default {
    "name": "Versandkosten-Motivator",
    "info_text": "Mit unserem Plugin wird im Warenkorb eine Anzeige integriert, die dem Kunden deutlich zeigt, wie viel er noch hinzufügen muss, um die Schwelle für kostenlosen Versand zu erreichen. Dies Information motiviert den Kunden mehr zu kaufen.",
    "basket": "Warenkorb",
    "basket_popup": "Warenkorb-Popup",
    "free": "Kostenlos",
    "threshold": "Versandgrenze erreicht",
    "heading": "Überschrift",
    "bg_color": "Hintergrundfarbe",
    "border_color": "Randfarbe",
    "line_color": "Linienfarbe",
    "text_color": "Textfarbe",
}
