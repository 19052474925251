<template>
  <div>
    <label>{{ label }}</label>
    <div class="btn-group">
      <button :class="['btn btn-falcon-default btn-sm', {active: inputValue === 'start'}]" @click="changeAlign('start')">
        <span class="fas fa-align-left fs-11 me-1"></span>
      </button>
      <button :class="['btn btn-falcon-default btn-sm', {active: inputValue === 'center'}]"
              @click="changeAlign('center')">
        <span class="fas fa-align-center fs-11 me-1"></span>
      </button>
      <button :class="['btn btn-falcon-default btn-sm', {active: inputValue === 'end'}]"
              @click="changeAlign('end')">
        <span class="fas fa-align-right fs-11 me-1"></span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "../../../../../../../adminmenu/view/components/Input";

export default {
  components: {Input},
  props: {
    value: String,
    label: String
  },
  mounted() {
    if ((typeof this.inputValue === 'undefined' || this.inputValue === null || this.inputValue === '') && this.defaultValue) {
      this.inputValue = this.defaultValue;
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    changeAlign(align) {
      if (this.inputValue === align) {
        this.inputValue = null;
      } else {
        this.inputValue = align;
      }
    }
  }
};
</script>
