import Builder from '../builder/store/index';
import i18n from "../../../../../adminmenu/i18n";
import Vue from "vue";

export default {
    namespaced: true,
    state: () => ({
        templates: [],
        template: null,
        designs: [],
        plugin: {},
    }),
    mutations: {
        setTemplates(state, info) {
            state.templates = info;
        },
        setTemplate(state, template) {
            state.template = template;
        },
        setDesigns(state, designs) {
            state.designs = designs;
        },
        setPlugin(state, info) {
            state.plugin = info;
        }
    },
    actions: {
        updatePluginStatus: (ctx, status) => {
            return ctx.dispatch('internalRequest', {
                data: {
                    status: status,
                    requests: ['PopupBuilder.updateStatus']
                }
            }, {
                root: true
            }).then(({PopupBuilder: {success}}) => {
                ctx.commit('setPlugin', {...ctx.state.plugin, status: status});
                if (success === true) {
                    Vue.prototype.$notification.success(i18n.t('settings_saved'), {showCloseIcn: true, position: 'topCenter'});
                } else {
                    Vue.prototype.$notification.error(i18n.t('settings_saved_error'), {showCloseIcn: true, position: 'topCenter'});
                }
            });
        },
        loadDesigns: (ctx) => {
            ctx.commit('setDesigns', []);
            return ctx.dispatch('internalRequest', {
                data: {requests: ['PopupBuilder.getDesigns']}
            }, {
                root: true
            }).then(({PopupBuilder: {designs, info}}) => {
                ctx.commit('setDesigns', designs);
                ctx.commit('setPlugin', info);
            });
        },
        loadTemplates: (ctx) => {
            ctx.commit('setTemplates', []);
            ctx.dispatch('internalRequest', {
                data: {requests: ['PopupBuilder.getTemplates']}
            }, {
                root: true
            }).then(({PopupBuilder: {templates, info}}) => {
                ctx.commit('setTemplates', templates);
                ctx.commit('setPlugin', info);
            });
        },
        clear(ctx) {
            ctx.commit('setTemplate', null);
            ctx.commit('setDesigns', []);
            ctx.dispatch('Builder/clear');
        },
        loadTemplate: (ctx, {params}) => {
            ctx.dispatch('clear');
            ctx.dispatch('internalRequest', {
                data: {
                    ...params,
                    requests: ['PopupBuilder.getTemplate']
                }
            }, {
                root: true
            }).then(({PopupBuilder: {template}}) => {
                ctx.commit('setTemplate', template);
            });
        },
        removeDesign: (ctx, {designId}) => {
            return ctx.dispatch('internalRequest', {
                data: {
                    designId,
                    requests: ['PopupBuilder.removeDesign']
                }
            }, {
                root: true
            }).then(({PopupBuilder: {template, designId}}) => {
                ctx.dispatch('clear');
                ctx.commit('setTemplate', template);
                return Promise.resolve({designId});
            });
        },
        saveTemplate: (ctx, {templateId, designId = null, template}) => {

            const formData = new FormData(),
                files = ctx.getters['Builder/getFiles'];

            Object.entries(files).forEach(([key, value]) => {
                if (value instanceof File && value.name) {
                    formData.append('file_' + key, value, value.name);
                }
            });

            if (designId) {
                formData.append('designId', designId);
            }

            formData.append('templateId', templateId);
            formData.append('template', JSON.stringify(template));
            formData.append('requests[]', 'PopupBuilder.saveTemplate');
            formData.append('token', JTL_TOKEN);

            return ctx.dispatch('internalRequest', {data: formData}, {
                root: true
            }).then(({PopupBuilder: {designId}}) => {
                return Promise.resolve({designId});
            });
        },
    },
    getters: {
        getTemplates(state) {
            return state.templates
        },
        getTemplate(state) {
            return state.template
        },
        getDesigns(state) {
            return state.designs
        },
        getPlugin(state) {
            return state.plugin
        },
    },
    modules: {Builder}
};


