export default {
    "info_text": "Unser Plugin ermöglicht die Reservierung von Warenkörben, was Knappheit vermittelt und Kunden zum Kauf motiviert. Darüber hinaus erhöht es die Zufriedenheit, da das Produkt während des Checkouts nicht ausverkauft wird.",
    "timer": "Reservierungszeit in Sekunden",
    "message": "Position der Meldung",
    "style_message": "Aussehen",
    "timer_update": "Update timer",
    "time_reservation": "Reservierungszeit in Sekunden",
    "cart_ban_time": "Reservierungsintervallzeit in Sekunden",
    "cart_timer_message_background": "Hintergrundfarbe",
    "cart_timer_message_font_color": "Textfarbe",
    "selector": "Selektor im Template",
}
