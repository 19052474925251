<template>
    <div>
        <input type="file" :name="name" style="display: none" :for="form">
        <div class="fileinput background-image"
             :class="`${src ? 'fileinput-exists' : 'fileinput-new'} ${inputClass} ${showPreview ? 'show-preview' : ''}`"
             data-provides="fileinput"
             :data-target="name"
             :ref="name"
             style="background-size: cover;background-position: 50%;"
             :style="{'background-image': (showPreview && src) && `url(${src})`}">

            <!-- return result only this case -->
            <div class="fileinput-preview" :style="{'display': 'none'}" data-trigger="fileinput"></div>

            <input type="file" ref="image" @change="onChange"
                   :name="name + '_tmp_file'" :id="id" :accept="accept">
            <!--        :required="required"-->

            <label class="fileinput-inner">
                <template v-if="displayName">
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27.5 6.875C28.2293 6.875 28.9288 7.16473 29.4445 7.68046C29.9603 8.19618 30.25 8.89565 30.25 9.625V23.375C30.25 24.1043 29.9603 24.8038 29.4445 25.3195C28.9288 25.8353 28.2293 26.125 27.5 26.125H5.5C4.77065 26.125 4.07118 25.8353 3.55546 25.3195C3.03973 24.8038 2.75 24.1043 2.75 23.375V9.625C2.75 8.09875 3.97375 6.875 5.5 6.875H27.5ZM6.875 22H26.125L19.9375 13.75L15.125 19.9375L11.6875 15.8125L6.875 22Z"
                            fill="#13C3AE" />
                    </svg>
                    <span class="fileinput-new">{{ uploadText }}</span>
                    <span class="fileinput-exists">
                    <span class="fileinput-filename">Shop-logo</span>
                </span>
                </template>
                <template v-else>
                <span class="fileinput-new">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path
                            d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
                            fill="#21C7B2" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M15 9C15.1768 9 15.3464 9.07024 15.4714 9.19526C15.5964 9.32029 15.6667 9.48986 15.6667 9.66667V15C15.6667 15.1768 15.5964 15.3464 15.4714 15.4714C15.3464 15.5964 15.1768 15.6667 15 15.6667H9.66667C9.48986 15.6667 9.32029 15.5964 9.19526 15.4714C9.07024 15.3464 9 15.1768 9 15C9 14.8232 9.07024 14.6536 9.19526 14.5286C9.32029 14.4036 9.48986 14.3333 9.66667 14.3333H14.3333V9.66667C14.3333 9.48986 14.4036 9.32029 14.5286 9.19526C14.6536 9.07024 14.8232 9 15 9Z"
                              fill="#F1F2F3" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.3333 14.9999C14.3333 14.8231 14.4035 14.6535 14.5285 14.5285C14.6535 14.4035 14.8231 14.3333 14.9999 14.3333H20.3333C20.5101 14.3333 20.6796 14.4035 20.8047 14.5285C20.9297 14.6535 20.9999 14.8231 20.9999 14.9999C20.9999 15.1767 20.9297 15.3463 20.8047 15.4713C20.6796 15.5963 20.5101 15.6666 20.3333 15.6666H15.6666V20.3333C15.6666 20.5101 15.5963 20.6796 15.4713 20.8047C15.3463 20.9297 15.1767 20.9999 14.9999 20.9999C14.8231 20.9999 14.6535 20.9297 14.5285 20.8047C14.4035 20.6796 14.3333 20.5101 14.3333 20.3333V14.9999Z"
                              fill="#F1F2F3" />
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <path
                            d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
                            fill="white" />
                        </clipPath>
                        </defs>
                    </svg>
                </span>
                    <template v-if="!showPreview">
                    <span class="fileinput-exists">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
                                fill="#21C7B2" />
                            <path
                                d="M19.4531 19.457H10.5414C10.2437 19.457 10 19.804 10 20.2285C10 20.6527 10.2437 20.9999 10.5414 20.9999H19.4586C19.7563 20.9999 20 20.6527 20 20.2285C20 19.804 19.7563 19.457 19.4531 19.457Z"
                                fill="#F1F2F3" />
                            <path
                                d="M12.1025 13.3304L14.4387 10.9233V16.8567C14.4387 17.1554 14.694 17.4 15.0058 17.4C15.3178 17.4 15.5728 17.1554 15.5728 16.8567V10.9233L17.909 13.3304C18.0224 13.4444 18.17 13.5043 18.3229 13.5043C18.459 13.5043 18.6009 13.4554 18.7085 13.3575C18.9355 13.1511 18.9525 12.8087 18.737 12.5914L15.4141 9.17385C15.3063 9.06521 15.1589 9 15.0002 9C14.8414 9 14.694 9.06521 14.5863 9.17385L11.2634 12.5914C11.048 12.8087 11.065 13.1564 11.2917 13.3575C11.5299 13.564 11.8871 13.5477 12.1025 13.3304Z"
                                fill="#F1F2F3" />
                        </svg>
                    </span>
                    </template>
                </template>
            </label>

            <template v-if="fileName !== null">
                <input type="hidden" :name="fileName" :value="src">
            </template>
        </div>
    </div>
</template>

<script>
import 'jasny-bootstrap/js/fileinput';

export default {
    props: {
        id: String,
        returnSrc: {
            type: Boolean,
            default: false
        },
        src: {
            type: String,
            default: null
        },
        size: Array,
        maxSize: {
            type: Number,
            default: 1
        },
        checkImage: {
            type: Boolean,
            default: true
        },
        inputClass: String,
        uploadText: String,
        showPreview: {
            type: Boolean,
            default: false
        },
        name: String,
        accept: {
            type: String,
            default: 'image/*'
        },
        fileName: {
            type: String,
            default: null
        },
        displayName: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        form: {
            type: String
        }
    },
    name: "ImageUploadWidget",
    mounted() {
        let vm = this;
        let fileinput = vm.$refs[vm.name];
        $(fileinput).on('change.bs.fileinput', (a, b) => {
            if (typeof b === 'undefined') {
                const files = this.$refs.image.files;

                if (files.length > 0) {
                    const file = files[0];
                    if (file.name.indexOf('.svg') > -1 || file.type.indexOf('svg') > -1) {
                        const reader = new FileReader();
                        reader.onload = function(e) {
                            let svg = String(e.target.result);
                            const regex = RegExp('<svg', 'g');
                            const search = regex.exec(svg);

                            svg = svg.substring(search.index).replace(/<\!--.*?-->/g, "");
                            svg = svg.trim();

                            const base64 = "data:image/svg+xml;base64," + Buffer.from(svg, 'base64');
                            vm.$emit('upload-widget:src', base64, vm.name);

                        }
                        reader.readAsText(file);
                    }
                }
            }

            if ($(a.target).data('target') !== vm.name || !b) {
                return false;
            }

            if (vm.checkImage) {
                const file = vm.$refs.image.files[0];
                let img = new Image();
                let _URL = window.URL || window.webkitURL;
                img.src = _URL.createObjectURL(file);

                img.onload = () => {
                    let checked = true;
                    if (vm.checkImage) {
                        checked = vm.checkImageFunc(file, img);
                        _URL.revokeObjectURL(img.src);
                    }

                    if (checked) {
                        if (vm.showPreview) {
                            $(fileinput).css('background-image', 'url(' + b.result + ')')
                        }
                        if (vm.returnSrc) {
                            vm.$emit('upload-widget:src', b.result, vm.name);
                        }
                    } else {
                    }

                }
            } else {
                if (vm.showPreview) {
                    $(fileinput).css('background-image', 'url(' + b.result + ')')
                }
                if (vm.returnSrc) {
                    vm.$emit('upload-widget:src', b.result, vm.name);
                }
            }

            $(fileinput).fileinput('reset');
        });
    },
    methods: {
        selectFile(){
          this.$refs.image.click();
        },
        onChange($event) {
            this.$emit('change', $event)
        },
        checkImageFunc(file, img) {
            let vm = this;

            // checking file type
            if (this.accept.indexOf(file.type) === -1) {
                this.$notification.error(this.$t('file_not_supported'), { showCloseIcn: true, position: 'topCenter' });
                return false;
            }

            // checking size
            if (file.size > (this.maxSize * 1024 * 1024)) {
                this.$notification.error(this.$t('image_too_big', {max:this.maxSize}), { showCloseIcn: true, position: 'topCenter' });
                return false;
            }

            const width = this.size[0], height = this.size[1];
            // let canvas = document.getElementById('imageCanvas');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            // checking width/height
            if (img.width !== width || img.height !== height) {
                vm.$notification.error(vm.$t('image_sizes_not_correct', {
                    width: width,
                    height: height
                }), { showCloseIcn: true, position: 'topCenter' });
                return false;
            }

            // checking transparency
            canvas.width = img.width;
            canvas.height = img.height;

            // ctx.drawImage(img, 0, 0, img.width, img.height);
            ctx.drawImage(img, 0, 0);
            let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data,
                transparency = false,
                i = 0, iMax = imgData.length;

            for (i; i < iMax; i += 4) {
                if (imgData[i + 3] < 255) {
                    transparency = true;
                    break;
                }
            }

            if (transparency) {
                vm.$notification.error(vm.$t('image_is_transparent'), { showCloseIcn: true, position: 'topCenter' });
                return false;
            }

            return true;
        }
    }
}
</script>

<style lang="scss" scoped>

/*canvas {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*}*/

input[type=file] {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
}

.fileinput {
    margin: 0;
    height: 75px;
    border: 1px dashed rgba(50, 61, 69, 0.3);
    background-color: #FBFCFC;
    color: transparentize(#1f1f20, 0.3);
    font-size: 14px;
    position: relative;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &.has-error {
        background-color: #FCEBEB;
    }

    label.fileinput-inner {
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-weight: normal;
        font-size: 14px;
    }

    svg {
        width: 30px;
        height: 30px;
    }
}

.upload-symbol,
.upload-splash,
.upload-banner_logo {
    border-radius: 23px;
}

.upload-banner_logo {
    height: 100px;
    width: 100px;
    margin: 0 auto;
}

.upload-symbol {
    height: 100px;
}

.upload-splash {
    height: 145px;
}

.upload-logo {
    height: 50px;
    border-radius: 8px;
    justify-content: flex-start;

    @media screen and (max-width: 635px) {
        height: 40px;
    }

    label.fileinput-inner {
        padding: 0 10px;
        text-align: left;
    }

    svg {
        width: 35px;
        height: 25px;
        margin-right: 10px;
    }
}

.upload-banner {
    width: 266px;
    height: 120px;
    border-radius: 8px;
    margin: 0 auto;
}

.fileinput:hover svg {
    /*box-shadow: 0px 4px 9px rgba(33, 199, 178, 0.5);*/
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
    display: none;
}

.show-preview .has-background + .fileinput-inner  .fileinput-new  {
    display: none;
}
</style>
