<template>
  <div class="upsell-popup-container">
    <slot name="close"></slot>
    <div class="row m-0 w-100">
      <div class="col-sm-5 p-0">
        <slot name="image"></slot>
      </div>
      <div class="col-sm-7 p-0 w-100">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {templateMixin} from "../mixins/PropsMixin";

export default {
  mixins: [templateMixin],
}
</script>
