export default {
    "name": "Adressprüfung",
    "api_key": "Google API key",
    "create_api_key": "Hier klicken um einen API Key zu generieren",
    "info_text": "Unser Plugin überprüft die Adressen Ihrer Kunden während des Kaufvorgangs und weist auf etwaige Fehler hin. Es nutzt die Google-Schnittstelle, um falsche Postleitzahlen oder Tippfehler zu erkennen und bietet Korrekturen an. So stellen Sie sicher, dass die Adressdaten korrekt sind und sparen Zeit und Geld.",
    "instruction": {
        "title": "Anleitung:",
        "list": [
            "Öffnen Sie die Seite der Google Maps Platform unter „Schlüssel & Anmeldedaten“:</br><a href='https://console.cloud.google.com/projectselector2/google/maps-apis/credentials?utm_source=Docs_CreateAPIKey' target='_blank'>Google Maps Platform - Schlüssel & Anmeldedaten</a>",
            "Erstellen Sie ein neues Projekt. Klicken Sie dazu oben rechts auf „Projekt erstellen“ (Create project).",
            "Geben Sie den Namen des Projekts ein und optional auch die Organisation. <b>Falls Sie bereits eine Rechnungsadresse hinterlegt haben, werden Sie möglicherweise aufgefordert, diese hinzuzufügen. Google bietet sehr großzügige Freikontingente an, die für die meisten Shops vollkommen ausreichend sind.</b>",
            "Nach der Erstellung des Projekts werden Sie zur Projektseite weitergeleitet.",
            "Falls keine Rechnungsadresse vorhanden ist, werden Sie aufgefordert, eine zu erstellen. Klicken Sie auf „Kontoeinrichtung abschließen“ (Finish account setup), um zur Seite für die Erstellung der Rechnungsadresse zu gelangen. Nach dem Erstellen kehren Sie zur Projektseite zurück. Klicken Sie erneut auf „Kontoeinrichtung abschließen“, um das Projekt mit der Rechnungsadresse zu verknüpfen und füllen Sie anschließend das Formular aus.",
            "Sobald das Projekt und die Rechnungsadresse erstellt und verknüpft sind, wird Ihnen der API-Schlüssel in einem Fenster angezeigt.",
            "Den API-Schlüssel können Sie auch später im Projekt unter dem Reiter „Schlüssel & Anmeldedaten“ im Feld „Maps Platform API Key“ > „Schlüssel anzeigen“ einsehen.",
        ]
    }
}
