<template>
  <div class="mb-3">
    <Input v-model="inputValue" :input-tag="tag" :label-text="label"/>
  </div>
</template>

<script>
import Input from "../../../../../../../adminmenu/view/components/Input";

export default {
  components: {Input},
  props: {
    value: String,
    tag: String,
    label: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>
