<template>
  <div class="upsell-popup-view" :style="elementExternalStyle">
    <slot/>
  </div>
</template>
<script>
import {elementMixin} from '../mixins/PropsMixin';

export default {
  mixins: [elementMixin],
}
</script>
