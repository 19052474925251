export default {
    "zoom_lupe_global": "Allgemeine Einstellungen",
    "lens_shape": {
        "label": "Zoomform",
        "square": 'Quadrat',
        "round": 'Kreis',
    },
    "zoom_type": {
        "label": "Objektivzoom:",
        "window": 'Nein',
        "lens": 'Ja',
    },
    "lightbox_off": {
        "label": "Lightbox deaktivieren:",
        "N": 'Nein',
        "Y": 'Ja',
    },
    "pq_selector": {
        "label": "PHP-Query-Selektor",
    },
    "info_text": "Entdecken Sie jedes Detail mit unserer Zoom Lupe! Dieses Plugin ermöglicht es Nutzern, beim Überfahren mit der Maus das Artikelbild zu vergrößern, um die Produktdetails zu erkunden. Steigern Sie die Kaufbereitschaft Ihrer Kunden durch eine verbesserte Visualisierung."
}
