export default {
    "name": "Bonuspunkte",
    "info_text": "Erhöhen Sie die Anzahl der Wiederholungskäufer, indem Sie Bonuspunkte anbieten. Kunden können diese bei ihrem nächsten Einkauf einsetzen, um einen Rabatt zu erhalten. Steigern Sie die Kundenbindung und fördern Sie wiederkehrende Käufe durch unser Bonuspunkte-Programm.",
    "general_settings": "Einstellungen",
    "documentation": "Dokumentation",
    "import": "Importieren",
    "export": "Exportieren",
    "import_export": "Importieren/Exportieren",
    "translations": "Translations",
    "templates": "Templates",
    "templates_settings_message": "Diese Einstellungen gelten nur für Ihr Shoptemplate. Die App haben wir bereits voreingestellt.",
    "customer_overview": "Kundenübersicht",
    "additional_fields": "Zusätzliche Felder",
    "power_on_flag":"Einschalten?",
    "select_files": "Dateien auswählen",
    "drop_or_add_files": "Dateien hier ablegen oder hinzufügen",
    "emailSearchOnly": "Suche nur nach E-Mail möglich",
    "bonusCoinsFor": "Bonusmünzen für",
    "creditForFreeIcon": "Creditangabe für kostenloses Icon",
    "coinIconCredit": "Coin icons created by Good Ware - Flaticon",
    "earnBonusPoints": "Bonuspunkte verdienen",
    "calculateBonusPoints": "Bestimmen Sie welchen prozentualen Wert der Kunde vom Kauf erhält. Wenn Sie zum Beispiel 50 eintippen, erhält der Kunde für einen Einkauf über 70 Euro 35 Bonuspunkte.",
    "settings": {
        "activate_bonus_points": " Bonuspunkte aktivieren",
        'subheading': 'Wie viel Bonuspunkte erhält der Kunde für den Kaufbetrag in Prozent?',
        'subheading2': 'Wie viel erhält der Kunde für den Bonus?',
        'product_title': 'PRODUKTDETAILSEITE',
        'success_title': 'BESTELLABSCHLUSS',
        'basket_title': 'WARENKORB',
        'account_title': 'MEIN KONTO',
        'confirmation_title': 'BESTÄTIGUNG',
        'rate': 'Welchem Wert entspricht ein Bonuspunkt? Echtzeitanzeige: 1 Bonus = {rate} €',
        'rate_help': 'Wie viel echtes Guthaben erhält man für eine Bonusmünze? Zum Beispiel bei einem Wert von 0,1 wird ein Bonus 0,1 €',
        'days_before': 'Nach wie vielen Tagen sollen die Punkte gutgeschrieben werden?',
        'exclude_shipping': 'Versandkosten mitzählen',
        'minimal_limit': 'Ab wie viel Punkten darf eingelöst werden?',
        'minimum_order_value': 'Mindestbestellwert unterschreiten erlauben',
        'minimum_order_value_help': 'Wenn der Warenkorbwert nach Bonuspunktabzug unter dem Mindestbestellwert ist, diesen ignorieren',
        'expired_period': 'Wann laufen die Punkte ab? (In Tagen, um Verfall auszustellen 0 eintippen)',
        'text_color': 'Textfarbe',
        'background_color': 'Hintergrundfarbe',
        'background_light_color': 'Helle Hintergrundfarbe'
    },
    "lang_vars": {
        "for": "Für",
        "insufficient": "Sie haben nicht genug Bonuspunkte",
        "title": "Aktuelles Guthaben",
        "basket_desc": "Wie viel Punkte wollen Sie einlösen?",
        "button_text": "Bonuspunkte einlösen",
        "order_title": "Punkte Warenkorb",
        "order_description": "Punkte aus früheren Käufen",
        "product_details_info": "Produktdetail",
        "use_digital_money": "Bonuspunkte",
        "you_have_receive": "Bonuspunkte erhalten",
        "minimum_order": "Mindestbestellwert",
        "no_bonus_products": "Bonuspunkte nicht einlösbar",
        "expired_bonus_message": "Bonuspunkte verfallen",
        "you_will_receive_at_date": "Offene Punkte (Bestellbestätigung)",
        "available": "Offene Punkte (Mein Konto)",
        'min_error': "Minimum Punkte zum Einlösen"
    },
    'errors': {
        '1': 'Ungültiges Dateiformat'
    }
}
