export default {
    "title_name": "Einfacher Verkauf von Zusatzprodukten",
    "settings": {
        'main': 'Zusatzprodukt im Warenkorb',
        'advanced': 'Template',
        "labels": {
            "title": "Überschrift",
            "description": "Beschreibungstext",
            "product" : "Artikel",
            "cart_selector": "PHP-Query-Selektor",
            "cart_method": "PHP-Query-Funktion",
        },
    },
    "info_text": "Mit unserem Plugin können Kunden Zusatzprodukte im Warenkorb mit einem Klick bestellen. Ob Geschenkverpackungen oder Versicherungen - der Prozess wird vereinfacht, um die Kundenerfahrung zu verbessern und die Umsätze zu steigern.",
    "search": {
        "no_results_found_for_term": "Keine Ergebnisse gefunden für {term}",
        // "minimum_search_characters": "Start typing to search by product name or number (minimum 3 symbols)",
        "minimum_search_characters": "Beginnen Sie mit der Eingabe, um nach Produktname oder -nummer zu suchen (mindestens 3 Symbole).",
    },
    "pq": {
        "methods": {
            "after": "Nach dem ausgewählten Element platzieren",
            "append": "Am unteren Rand des ausgewählten Elements platzieren",
            "before": "Vor dem ausgewählten Element platzieren",
            "prepend": "Oben auf dem ausgewählten Element platzieren",
            "replaceWith": "Anstelle des ausgewählten Elements platzieren",
        }
    }
}
