export default {
    "name": "URL Coupons",
    "info_text": "Mit unserem Plugin erstellen Sie spezielle URLs, die Ihre Kunden direkt in Ihren Shop führen. Beim Aufrufen der Seite wird der Gutscheincode sofort am unteren Bildschirmrand angezeigt – eine effektive Möglichkeit, den Kaufabschluss zu fördern.",

    "add_button": "Hinzufügen",
    'copy_link': "Kopieren",
    'copied': "Kopiert",
    "delete_coupon_warnings": "Löschen?",
    "coupon_deleted": "Gelöscht",
    "coupon_patch": "Kupon URL Pfad (Domain.de/<b>PFAD</b>/Nummer)",
    "error_messages": {
        "add_dublicate": "Dieser Coupon ist bereits konfiguriert",
        "add_wrong_code": "Dieser Gutschein existiert nicht",
        "add_coupon_expired": "Der Gutschein ist abgelaufen",
        "add_coupon_low": "Mindestens 2 Zeichen pro Gutschein",
    },

    "table_header": {
        "palette": "Farben",
        "background": "Hintergrund",
        "button_background": "Button Hintergrund",
        "text_color": "Textfarbe",
        "coupon": "Kupon",
        "link": "Link",
        "description": "Beschreibung",
        "actions": "Aktion"
    }
};