export const EDITOR_MODE_VIEW = 'view'
export const EDITOR_MODE_MENU = 'menu'
export const EDITOR_MODE_EDITOR = 'editor'
export const EDITOR_MODE_ICON = 'icon'

export const EDITOR_MODE_TYPES = [
    EDITOR_MODE_VIEW,
    EDITOR_MODE_EDITOR,
    EDITOR_MODE_MENU,
    EDITOR_MODE_ICON,
];
