<template>
  <div @mouseover="highlight(true)"
       @mouseleave="highlight(false)"
       @click="activeElement(element)" class="upsell-popup-view" :style="elementExternalStyle">
    <slot/>
     <div class="upsell-popup-highlighted" :data-label="$t('PopupBuilder.click_to_edit')" v-if="highlighted && getActiveElement !== element">
    </div>
  </div>
</template>
<script>
import {elementMixin} from '../mixins/PropsMixin';
import {mapGetters, mapMutations} from "vuex";

export default {
  mixins: [elementMixin],
  data() {
    return {
      highlighted: false
    }
  },
  computed: {
    ...mapGetters('PopupBuilder/Builder', ['getActiveElement']),
    isCloseBtn() {
      return this.element.slot && this.element.slot.toLowerCase() === 'close';
    },
  },
  methods: {
    ...mapMutations('PopupBuilder/Builder', ['setActiveElement']),
    activeElement(element) {
      if (!this.isCloseBtn) {
        this.setActiveElement(element);
      }
    },
    highlight(status) {
      if (!this.isCloseBtn) {
        this.highlighted = status;
      }
    },
  }
}
</script>
