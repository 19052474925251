<template>
  <ModeSwitcher :element="element" :editorMode="editorMode">
    <template v-slot:view>
      <div class="upsell-popup-img-container"
           :style="containerStyle">
        <img class="upsell-popup-img"
             :style="style"
             :src="preview || elementOptions.src"
             alt="">
      </div>
    </template>
    <template v-slot:editor>
      <div class="upsell-popup-img-container"
           :style="containerStyle">
        <img class="upsell-popup-img"
             :style="style"
             :src="preview || elementOptions.src"
             alt="">
      </div>
    </template>
    <template v-slot:icon>
      <span class="fas far fa-file-image fs-4"></span>
    </template>
    <template v-slot:menu>
      <div class="row">
        <div class="col-12">
          <ImageUploader v-model="src"
                         :showBtnClose="!!preview"
                         @changeFile="changeFile"
                         @changePreview="changePreview"
                         @resetView="resetView"/>
        </div>
        <div class="col-12">
          <Input v-if="!preview" v-model="elementOptions.src" :label="$t('PopupBuilder.image_url')"/>
        </div>
        <div class="col-sm-6">
          <Input v-model="elementOptions.width" :label="$t('PopupBuilder.width') + ' (px)'"/>
        </div>
        <div class="col-sm-6">
          <Input v-model="elementOptions.height" :label="$t('PopupBuilder.height') + ' (px)'"/>
        </div>
      </div>
    </template>
  </ModeSwitcher>
</template>

<script>
import {elementMixin, editorModeMixin} from '../mixins/PropsMixin';
import ModeSwitcher from "../components/ModeSwitcher";
import ImageUploader from "./properties/ImageUploader";
import Input from "./properties/Input";
import Align from "./properties/Align";
import {mapMutations, mapGetters} from "vuex";
import {formatDimensionValue} from '../utils/utils'

export default {
  mixins: [elementMixin, editorModeMixin],
  components: {
    ModeSwitcher,
    ImageUploader,
    Input,
    Align
  },
  computed: {
    ...mapGetters('PopupBuilder/Builder', ['getPreviews']),
    preview() {
      return this.getPreviews[this.uniqueId];
    },
    src() {
      return this.preview || this.elementOptions.src;
    },
    uniqueId() {
      return this.element.uniqueId;
    },
    containerStyle() {
      return {
        justifyContent: this.elementOptions.justifyContent,
        alignItems: this.elementOptions.alignItems,
      };
    },
    style() {
      return {
        width: formatDimensionValue(this.elementOptions.width),
        height: formatDimensionValue(this.elementOptions.height)
      };
    },
  },
  methods: {
    ...mapMutations('PopupBuilder/Builder', ['setPreview', 'setFile']),
    resetView() {
      this.setPreview({
        uniqueId: this.uniqueId,
        preview: null
      });
      this.setFile({
        uniqueId: this.uniqueId,
        file: null
      });
    },
    changePreview(preview) {
      this.setPreview({
        uniqueId: this.uniqueId,
        preview
      });
    },
    changeFile(file) {
      this.setFile({
        uniqueId: this.uniqueId,
        file
      });
    }
  }
}
</script>
