<template>
  <div :class="{
        'row' : descText && descType === 'side'
    }">

    <div :class="{
            'form-floating': labelFloat,
            'col-12 col-md-6 mb-2 mb-md-0': descText && descType === 'side'
        }">

      <template v-if="labelText && !labelFloat">
        <label
            class="form-label"
            :class="{
                        'text-danger': eriHas
                    }"
            :for="idx"
        >
          {{ labelText }}
          <template v-if="descText && descType === 'label-help'">
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            :data-original-title="descText"
                        >
                            <small class="fas fa-question-circle text-primary" data-fa-transform="shrink-4 down-2"/>
                        </span>
          </template>
        </label>
      </template>

      <template v-if="elementType('input')">
        <input
            class="form-control"
            :class=" { 'border border-danger' : eriHas } "
            :id="idx" :name="inputName ? inputName : idx"
            :type="inputType"
            v-model="inputValue"

            :placeholder="inputType==='url' ? 'https://' : placeholder "
            :maxlength="maxlength"

            :data-toggle="descText && descType === 'tag' ? 'tooltip' : null"
            data-placement="top"
            :data-original-title="descText"

            :data-vv-as="vvAs ? vvAs : labelText"
            v-validate="typeof valid === 'string' ? `${valid}` : valid"
            :data-vv-scope="validScope"
            :disabled = "disabled"
        />
      </template>

      <template v-if="labelText && labelFloat">
        <label
            class="form-label"
            :class="{
                        'text-danger': eriHas
                    }"
            :for="idx"
        >
          {{ labelText }}
          <template v-if="descText && descType === 'label-help'">
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            :data-original-title="descText"
                        >
                            <i class="fas fa-question-circle text-primary"/>
                        </span>
          </template>
        </label>
      </template>

      <template v-if="elementType('select')">
        <select
            class="form-control form-select"
            :class=" { 'border border-danger' : eriHas } "
            :id="idx" :name="inputName ? inputName : idx"
            v-model="inputValue"
            :data-vv-as="vvAs ? vvAs : labelText"
            v-validate="valid"
            :data-vv-scope="validScope"
        >
          <option :value="null" disabled v-text="$t('please_select')"/>
          <template v-for="(key, value) in select">
            <option :value="value" v-text="key"/>
          </template>
        </select>
      </template>

      <template v-if="elementType('textarea')">
                <textarea
                    class="form-control"
                    :class=" { 'border border-danger' : eriHas } "
                    v-model="inputValue"
                    :maxlength="maxlength"
                    rows="8"
                    :id="idx" :name="inputName ? inputName : idx"
                    :data-vv-as="vvAs ? vvAs : labelText"
                    v-validate="typeof valid === 'string' ? `${valid}` : valid"
                />
      </template>

    </div>

    <template v-if="descText && descType === 'side'">
      <div class="col-12 col-md-6">
        <div class="alert alert-info mb-0" v-text="descText"/>
      </div>
    </template>

    <template v-if="eriHas">
      <div class="alert alert-danger border-2 d-flex align-items-center mt-2" :class="{'col-12': descText}">
        <div class="bg-danger me-3 icon-item">
          <span class="fas fa-times-circle text-white fs-3"/>
        </div>
        <p class="mb-0 flex-1" v-text="errors.first(inputName? inputName : idx, validScope)"></p>
      </div>
    </template>

  </div>
</template>

<script>
/**
 * Universal component for inputs & select
 */
export default {
    name: "Input",
    props: {
        idx : String,
        labelText : String,
        labelFloat: {
            default : false,
            type    : Boolean
        },
        descText : {
            default : '',
            type    : String
        },
        descType : {
            default : 'side',
            type    : String
        },
        inputType : {
            default : 'text',
            type    : String
        },
        inputTag    : {
            default : 'input',
            type    : String
        },
        maxlength   : {
            default : null,
            type    : String
        },
        placeholder : String,
        select      : [Array, Object],
        inputName : String,
        value: [String, Number, Array],//array for v-model support
        valid: [String, Object],
        validScope: String,
        vvAs : String,
        error: Object,
        disabled: {
          default: false,
          type: Boolean
        }
    },
    computed: {
        /**
         * Error id with use valid scope
         * @returns {string}
         */
        eri(){
            let scope = this.validScope ? this.validScope : this.idx;
            return `${scope}.${this.idx}`;
        },
        /**
         * short function vee-validate.errors{ErrorBag}.has(field, scope)
         */
        eriHas(){
            return this.$validator.errors.has(this.inputName? this.inputName : this.idx, this.validScope);
        },
        inputValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    created() {
        this.$validator = this.$parent.$validator;
    },
    methods: {
        /**
         * Control form elements
         * @param tag
         * @returns {boolean}
         */
        elementType(tag) {
            if (tag !== this.inputTag) {
                return false;
            }
            let _return = false;
            switch (this.inputTag) {
                case 'input':
                    if (this.inputType === 'text' || this.inputType === 'number' || this.inputType === 'tel' || this.inputType === 'email' || this.inputType === 'password' || this.inputType === 'url') {
                        _return = true;
                    }
                    break;
                case 'select':
                    _return = true;
                    break;
                case 'textarea':
                    _return = true;
                    break;
            }
            return _return;
        }
    }
}
</script>

<style scoped>

</style>
