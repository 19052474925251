export default {
    "selector": "PHP-Query-Selektor",
    "pq": "PHP-Query-Funktion",
    "timer_type": "Design",
    "artikel": "Artikel",
    "status": "Status",
    "text": "Text",
    "datetime": "Datum des Countdowns",
    "portlet": "Sie können es über die Portlets auf die Startseite ziehen",
    "date_end_info": "Das Enddatum des Zählers ist das Ablaufdatum des Aktionspreises.",
    "info_text": "Unser Plugin bewirbt Sales mit einem Countdown, um Kunden dazu zu motivieren, schnell zuzuschlagen. Es integriert sich sowohl für globale Sales auf der Startseite als auch für einzelne Produkte mit eigenen Timern auf der Kategorie- und der Produktseite."
}
