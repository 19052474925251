export default {
    "name": "Personalisierte Mails",
    "triggers": {
        "user_group_id": {
            "title": "Kunden",
            "desc": "Wählen Sie die Kunden aus, die angeschrieben werden",
            "type": "select",
            "icon": "user-plus",
            "options": {
                1: {
                    "name": "Alle Kunden"
                },
                2: {
                    "name": "Kunden mit mind. einer Bestellung"
                },
                3: {
                    "name": "Newsletter Empfänger (*)"
                },
            }
        },
        "trigger_id": {
            "title": "Auslöser",
            "desc": "Wählen Sie ein Szenario",
            "type": "select",
            "icon": "scroll",
            "options": {
                1: {
                    "name": "Erinnerung an den Warenkorb",
                    "id": "MailIncompleteOrder"
                },
                2: {
                    "name": "Gutschein zum Geburtstag",
                    "id": "MailCustomerCoupon"
                },
                3: {
                    "name": "Produkte aus gekaufter Kategorie",
                    "id": "MailSimilarProducts"
                },
                4: {
                    "name": "Wir vermissen Sie",
                    "id": "MailLongAbsent"
                },
            }
        },
        "coupon": {
            "title": "Kupon",
            "desc": "Einstellungen für den Kupon",
            "icon": "flash",
            "type": {
                "title": "Type",
                "value": "eur",
                "options": {
                    "festpreis": "Betrag",
                    "prozent": " % ",
                },
            },
            "period": {
                "title": "Gültigkeitsdauer (Tage):",
            },
            "price": {
                "title": "Wert (Brutto):",
            }
        },
        "postponed": {
            "title": "Tage (Option)",
            "desc": "Days before sending mail",
            "type": "number",
            "icon": "clock",
            "options": {
                1: {
                    "title": "Zeitpunkt",
                    "desc": "Nach wie vielen Tagen soll der Kunde benachrichtigt werden?"
                },
                2: {
                    "title": "Zeitpunkt",
                    "desc": "Anzahl der Tage vor dem Event für den Versand des Kupons angeben."
                },
                3: {
                    "title": "Zeitpunkt",
                    "desc": "Nach wie vielen Tagen soll der Kunde benachrichtigt werden?"
                },
                4: {
                    "title": "Zeitpunkt",
                    "desc": "Nach wie vielen Tagen Abwesenheit soll der Kunde erinnert werden?"
                }
            },
        },
        "interval": {
            "title": "Intervall",
            "desc": "Wie oft soll der Kunde erinnert werden?",
            "type": "number",
            "icon": "hourglass",
            "options": {
                0: {
                    "title": "Repeat shipment?",
                    "desc": "Specify the number of days after which to repeat sending mail"
                },
            },
        },
        "repeat": {
            "title": "Anzahl der Wiederholungen",
            "desc": "Wiederholungen",
            "type": "number",
            "options": {
                0: {

                },
            },
        },
        "category_settings": {
            "title": "Kategorien ausschließen",
            "desc": "Wählen Sie Kategorien, für die keine Mails versendet werden sollen",
            "icon": "scroll",
            "type": {

            },
            "exclude": {

            },
        }
    },
    "trigger": {
        "title": "Auslöser",
        "options": {
            1: {
                "name": "Erinnerung an den Warenkorb",
                "id": "MailIncompleteOrder"
            },
            2: {
                "name": "Gutschein zum Geburtstag",
                "id": "MailCustomerCoupon"
            },
            3: {
                "name": "Produkte aus besuchter Kategorie",
                "id": "MailSimilarProducts"
            },
            4: {
                "name": "Wir vermissen Sie",
                "id": "MailLongAbsent"
            },
        }
    },
    "user_groups": {
        "title": "Kunden",
        "options": {
            1: "Alle Kunden",
            2: "Kunden mit mind. einer Bestellung",
            3: "Newsletter Empfänger (*)",
        }
    },
    "extra_option": {
        0: {
            "title": "Tage (Option)",
        },
        2: {
            "title": "Zeitpunkt",
        },
        4: {
            "title": "Zeitpunkt",
        }
    },
    "days": "Tage",
    "showAll": "Unterkategorien anzeigen",
    "info_news": "*  (tnewsletterempfaenger, tkunde.cNewsletter)",
    "save": "Szenario hinzufügen",
    "save_template": "Speichern",
    "template_modal_title2": "Diese Bezeichnungen für die Benachrichtigungen sehen die Kunden auf der \"Abbestellen\"-Seite",
    "edit": "Bearbeiten",
    "template_edit": "Benennung",
    "cancel": "Abbrechen",
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "update_template": "Template",
    "table_mail": "Name",
    "events": "Events",
    "settings": "Einstellungen",
    "status": "Status",
    "trigger_modal_title": "E-Mail Trigger",
    "template_modal_title": "Benennung",
    "back": "Zurück",
    "table_settings": "Interval",
    "mail_edit": "Ändern der E-Mail-Vorlage",
    "modal_title": "Wählen Sie aus, für welche Szenarien E-Mails versendet werden sollen",
    "info_text": "Nutzen Sie unsere Triggersysteme, um zeitnahe und relevante Marketing-E-Mails zu versenden. Diese E-Mails enthalten personalisierte Produktempfehlungen, die auf das Einkaufsverhalten der Kunden zugeschnitten sind. Dadurch steigern Sie das Engagement, erhöhen Käufe und stärken die Markentreue über Ihre wichtigsten externen Kanäle."
}
