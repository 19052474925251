export default {
    "info_text": "Nach dem Kauf präsentiert unser Plugin einen generierten Gutscheincode direkt auf der Bestellbestätigungsseite. Diese praktische Funktion fördert weitere Einkäufe und stärkt die Bindung zu Ihren Kunden, indem sie ein zusätzliches Anreizangebot erhalten.",
    "title": "Titel",
    "add_new": "Neue hinzufügen",
    "link": "Link",
    "image": "Bild",
    "actions": "Aktionen",
    "update": "Aktualisieren",
    "delete": "Löschen",
    "Coupon": "Kupon",
    "name": "Aftersell: Kupon anzeigen",
    "coupon_name": "Name",
    "value": "Wert",
    "min_order_value": "Mindestbestellwert",
    "min_next_order_value": "Mindestbestellwert für die nächste Bestellung",
    "select": "Wähle eine Option:",
    "valid_from": "Gültig ab:",
    "valid_to": "Gültig bis:",
    "invalid_percentage_for_coupon": "Der prozentuale Wert des Coupons darf 100 nicht überschreiten,",
    "text": "Titel",
    "text_2": "Beschreibung",
    "shopping": "Einkaufen!",
    "calender_clear": "Leeren",
    "notifications": {
        "coupon_created": "Coupon wurde erfolgreich erstellt",
        "coupon_create_failed": "Failed create coupon",
    }
}
