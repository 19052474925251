export function formatDimensionValue(value) {
    if (value && !isNaN(Number(value))) {
        return value + 'px';
    } else {
        return value;
    }
}

export function selectText(element) {
    if (element) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            console.warn("Could not select text in node: Unsupported browser.");
        }
    }
}
