<template>
  <div class="mb-2">
    <slot/>
  </div>
</template>

<script>
import {elementMixin} from '../mixins/PropsMixin';

export default {
  mixins: [elementMixin],
}
</script>
