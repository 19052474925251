<template>
  <div>
    <label>{{ label }}: {{ formatDimensionValue(value) }}</label>
    <input type="range" :min="min" :max="max" :step="step" v-model="inputValue" class="form-range">
  </div>
</template>

<script>
import {formatDimensionValue} from "../../utils/utils";

export default {
  props: {
    label: String,
    value: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
  },
  methods:{
    formatDimensionValue
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
}
</script>
