import '../../../../../adminmenu/assets/sass/app.scss';

import './styles.scss';
import Vue from 'vue';
import Vuex from "vuex";
import PopupBuilder from "../store";
import Popup from './Popup.vue';
import PopupManager from './PopupManager';
import VueRouter from 'vue-router';

Vue.use(Vuex);
Vue.use(VueRouter);

const store = new Vuex.Store({
    modules: {
        PopupBuilder
    }
});

const router = new VueRouter({
    mode: 'history'
})

const createVuePopup = function (template) {
    const wrapper = document.createElement('div');
    document.body.appendChild(wrapper);
    const vue = new Vue({
        router,
        store,
        render: function (h) {
            return h(Popup, {
                props: {
                    fromSite: true,
                    template
                }
            });
        }
    });

    vue.$mount(wrapper);

    return vue;
};

window.upsellPopupManger = new PopupManager(createVuePopup);


