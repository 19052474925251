<template>
  <ModeSwitcher :element="element" :editorMode="editorMode">
    <template v-slot:view>
      <div class="upsell-popup-btn-close-container">
        <button v-if="closePopup" class="upsell-popup-btn-close" @click="closePopup"></button>
        <button v-else class="upsell-popup-btn-close"></button>
      </div>
    </template>
    <template v-slot:editor>
      <div class="upsell-popup-btn-close-container">
        <button class="upsell-popup-btn-close"></button>
      </div>
    </template>
  </ModeSwitcher>
</template>

<script>
import {elementMixin, editorModeMixin} from '../mixins/PropsMixin';
import ModeSwitcher from "../components/ModeSwitcher";

export default {
  mixins: [elementMixin, editorModeMixin],
  components: {
    ModeSwitcher
  }
}
</script>

<style scoped>
.upsell-popup-btn-close-container {
  display: block;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 32px;
  height: 32px;
}

.upsell-popup-btn-close {
  display: block;
  border: unset !important;
  position: relative;
  z-index: 9;
  background-color: white !important;
  border-radius: 50%;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}


.upsell-popup-btn-close:before, .upsell-popup-btn-close:after {
  position: absolute;
  content: ' ';
  height: 25px;
  width: 3px;
  left: 14.25px;
  top: 3.6px;
  background-color: #333;
}

.upsell-popup-btn-close:hover {
  opacity: 1;
}

.upsell-popup-btn-close:before {
  transform: rotate(45deg);
}

.upsell-popup-btn-close:after {
  transform: rotate(-45deg);
}
</style>
